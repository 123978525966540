import React from "react"
import SeoComponent from "../components/atoms/SeoComponent"
import CookieNoticeBody from "../components/templates/CookieNoticeBody"
import GlobalTemplate from "../components/templates/GlobalTemplate"
import { launchingDate } from "../helpers/otherVariables"

const cookies = () => {
  return (
    <>
      <GlobalTemplate>
        <CookieNoticeBody launchDate={launchingDate} />
      </GlobalTemplate>
    </>
  )
}

export default cookies

export const Head = () => (
  <SeoComponent pageTitle="Blackness in Full Bloom | Cookie Notice" />
)

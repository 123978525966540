import React from "react"
import Content from "../atoms/textStyledComponents/Content"
import ExternalLink from "../atoms/textStyledComponents/ExternalLink"
import List from "../atoms/textStyledComponents/List"
import MainContainer from "../atoms/textStyledComponents/MainContainer"
import MainTitle from "../atoms/textStyledComponents/MainTitle"
import SectionTitle from "../atoms/textStyledComponents/SectionTitle"

const CookieNoticeBody = ({ launchDate }) => {
  return (
    <MainContainer>
      <MainTitle>Deutsch LA Blackness in Full Bloom Cookie Notice</MainTitle>
      <SectionTitle>{`Effective Date: ${launchDate}`}</SectionTitle>
      <Content>
        This Cookie Notice describes the different types of cookies and similar
        technologies used on the Deutsch LA Blackness in Full Bloom website from
        which you are accessing this policy (the “Site”). This policy provides
        you with information about how we use cookies and how you can control
        them.
      </Content>
      <Content>
        We can change this Cookie Notice at any time. The “Effective Date” at
        the top of this page indicates when this policy was last revised. Any
        changes will become effective when we make the revised Cookie Notice
        available on the Site.
      </Content>

      <SectionTitle>1. What are cookies and similar technologies?</SectionTitle>
      <Content>
        Cookies are a standard feature of websites that allow us to store small
        amounts of data on your computer about your visit to the Site. We use
        cookies to perform many different functions. For example, cookies:
      </Content>
      <List>
        {[
          "Help us learn which areas of the Site are useful and which areas need improvement;",
          "Allow us to remember you and your preferences when you return to the Site and otherwise improve your experience;",
          "Help us to serve online advertising to you that is relevant to your interests; and",
          "Measure the effectiveness of our online advertising and marketing communications.",
        ]}
      </List>
      <Content>
        We also use technologies similar to cookies, such as Flash Local Shared
        Objects (also known as Flash cookies) and pixel tags (also known as web
        beacons and clear GIFs), for similar purposes.
      </Content>

      <SectionTitle>2. What types of cookies do we use?</SectionTitle>
      <Content>
        Below we list the different types of cookies and similar technologies
        used on the Site.
      </Content>
      <List
        itemsTitle={[
          "Strictly Necessary Cookies.",
          "Functionality Cookies.",
          "Analytics Cookies.",
        ]}
      >
        {[
          "These cookies are required for the Site to function. They are used to ensure the safety, security, and integrity of the Site, as well as to enable users to move around the Site and use its features. Without these cookies, the Site will not perform as smoothly for you as we would like it to, and we may not be able to provide the Site or certain features of it. These cookies are often session-specific, which means they expire after your visit to the Site has ended. These cookies cannot be disabled.",
          "These cookies allow the Site to recognize you when you return, as well as to remember choices you made (such as your name, language, or region), so it can provide more personal features. These cookies will typically remain on your device until you choose to clear them. They cannot be disabled.",
          "These cookies collect information about your use of the Site and enable us to improve the way it works. For example, analytics cookies show us which are the most frequently visited pages on the Site, help us record any difficulties you have with the Site, and show us whether our content is effective. This allows us to see overall patterns of Site use, rather than the use of a single person. We use the information to analyze Site traffic, but we do not examine this information for individually identifying information. These cookies may be set by us or by third parties, and they typically stay on your computer until you delete them (see below).",
        ]}
      </List>

      <SectionTitle>
        3. How can you manage your cookie preferences?
      </SectionTitle>
      <Content>
        You can manage your cookie preferences at any time via our{" "}
        <span className="ot-sdk-show-settings text-[#ff0000] cursor-pointer">
          Cookie Preference Center.
        </span>
      </Content>
      <Content>
        Additionally, you can stop certain types of cookies from being
        downloaded on your computer by selecting the appropriate settings on
        your web browser. Most web browsers will tell you how to stop accepting
        new browser cookies, how to be notified when you receive a new browser
        cookie, and how to disable existing cookies. You can find out how to do
        this for your particular browser by clicking “help” on your browser’s
        menu or by visiting{" "}
        <ExternalLink>http://www.allaboutcookies.org</ExternalLink>. If you
        choose to delete or disable cookies and similar technologies, your
        experience on the Site may be diminished, and some features may not work
        as intended.
      </Content>
      <Content>
        If you have any questions, please contact us{" "}
        <a
          href="/privacy#how-to-contact-us"
          target="_blank"
          rel="noreferrer"
          className="text-[#ff0000]"
        >
          here
        </a>
        .
      </Content>
    </MainContainer>
  )
}

export default CookieNoticeBody
